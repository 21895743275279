<template>
  <v-time-picker
    v-model="picker"
    format="24hr"
    color="primary"
  ></v-time-picker>
</template>

<script>
import { ref } from 'vue'

export default {
  setup() {
    const picker = ref(null)

    return { picker }
  },
}
</script>
